import axios from "axios";

export async function getSimpleList() {
    return await new Promise((resolve, reject) => {
        axios.get("/admin/currency/list", {
            params: {
                page: 1,
                limit: 999,
                sort: "sort",
                order: "asc"
            }
        }).then(res => {
            if (res.data.code == 0) {
                resolve(res.data.data);
            } else {
                reject(res.data.msg)
            }
        }).catch(e => {
            reject(e)
        })
    })
}